import * as i18nIsoCountries from 'i18n-iso-countries';
import countriesLocale from 'i18n-iso-countries/langs/en.json';

i18nIsoCountries.registerLocale(countriesLocale);

const COUNTRIES_LIST: Record<string, string> = i18nIsoCountries.getNames('en', {
  select: 'alias',
});

const COUNTRIES_LIST_ALPHA3 = Object.keys(COUNTRIES_LIST).reduce<
  Record<string, string>
>((acc, alpha2) => {
  acc[i18nIsoCountries.alpha2ToAlpha3(alpha2)] = COUNTRIES_LIST[alpha2];
  return acc;
}, {});

export { i18nIsoCountries, COUNTRIES_LIST, COUNTRIES_LIST_ALPHA3 };
