'use client';

import React from 'react';
import { styled } from '@mui/material/styles';
import CompleteQuoteStatusIcon from './assets/complete-quote-status-icon.svg';
import ExpiredQuoteStatusIcon from './assets/expired-quote-status-icon.svg';
import InProgressQuoteStatusIcon from './assets/in-progress-quote-status-icon.svg';
import NewQuoteStatusIcon from './assets/new-quote-status-icon.svg';
import NoBidQuoteStatusIcon from './assets/no-bid-quote-status-icon.svg';
const LabelBase = styled('span')`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  padding: 5px 10px;
  word-break: normal;
  border-radius: 99px;
  color: #ffffff;
`;
const QuoteStatus = {
  STATUS_NEW: 'New',
  STATUS_NO_BID: 'No Bid',
  STATUS_EXPIRED: 'Expired',
  STATUS_COMPLETE: 'Complete',
  STATUS_IN_PROGRESS: 'In Progress'
};
export default QuoteStatus;
export const NewLabel = <LabelBase style={{
  backgroundColor: '#50B5FF'
}}>New</LabelBase>;
export const NoBidLabel = <LabelBase style={{
  backgroundColor: '#FC5A5A'
}}>Declined</LabelBase>;
export const ExpiredLabel = <LabelBase style={{
  backgroundColor: '#7200CC'
}}>Expired</LabelBase>;
export const CompleteLabel = <LabelBase style={{
  backgroundColor: 'var(--mui-palette-success-main)'
}}>
    Complete
  </LabelBase>;
export const InProgressLabel = <LabelBase style={{
  backgroundColor: '#7200CC'
}}>In Progress</LabelBase>;
export const UnknownLabel = <LabelBase style={{
  backgroundColor: '#EBEBEB',
  color: '#000000'
}}>
    Unknown
  </LabelBase>;
export const getLabelForStatusText = statusText => {
  let statusTextLabel = UnknownLabel;
  if (statusText === QuoteStatus.STATUS_NEW) {
    statusTextLabel = NewLabel;
  } else if (statusText === QuoteStatus.STATUS_NO_BID) {
    statusTextLabel = NoBidLabel;
  } else if (statusText === QuoteStatus.STATUS_EXPIRED) {
    statusTextLabel = ExpiredLabel;
  } else if (statusText === QuoteStatus.STATUS_COMPLETE) {
    statusTextLabel = CompleteLabel;
  } else if (statusText === QuoteStatus.STATUS_IN_PROGRESS) {
    statusTextLabel = InProgressLabel;
  }
  return statusTextLabel;
};
export const getQuoteStatusIcon = statusText => {
  switch (statusText) {
    case QuoteStatus.STATUS_NEW:
      return NewQuoteStatusIcon;
    case QuoteStatus.STATUS_NO_BID:
      return NoBidQuoteStatusIcon;
    case QuoteStatus.STATUS_EXPIRED:
      return ExpiredQuoteStatusIcon;
    case QuoteStatus.STATUS_COMPLETE:
      return CompleteQuoteStatusIcon;
    case QuoteStatus.STATUS_IN_PROGRESS:
      return InProgressQuoteStatusIcon;
  }
};