const STATES = [
  {
    name: 'Alabama',
    value: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    value: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    value: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    value: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    value: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    value: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    value: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    value: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    value: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    value: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    value: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    value: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    value: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    value: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    value: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    value: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    value: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    value: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    value: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    value: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    value: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    value: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    value: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    value: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    value: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    value: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    value: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    value: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    value: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    value: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    value: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    value: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    value: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    value: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    value: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    value: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    value: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    value: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    value: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    value: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    value: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    value: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    value: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    value: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    value: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    value: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    value: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    value: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    value: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    value: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    value: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    value: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    value: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    value: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    value: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    value: 'Wyoming',
    abbreviation: 'WY',
  },
];

module.exports = {
  states: STATES,
  stateNames: STATES.map((state) => state.name),

  getStateAbbreviation(stateName) {
    return (
      STATES.find(
        (state) => state.value.toLowerCase() === stateName.toLowerCase(),
      )?.abbreviation || ''
    );
  },

  getStateName(stateAbbreviation) {
    return (
      STATES.find(
        (state) =>
          state.abbreviation.toLowerCase() === stateAbbreviation.toLowerCase(),
      )?.value || ''
    );
  },
};
