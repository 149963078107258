import STATES from '@/shared/States.js';
import { i18nIsoCountries } from '@/shared/countriesList';
import AddressInstance from '@/shared/types/models/AddressInstance';
type Address = Pick<AddressInstance, 'id' | 'primary' | 'companyName' | 'personName' | 'street' | 'street2' | 'city' | 'state' | 'zipCode' | 'countryCode'>;
const getCountryName = (countryCode: string) => i18nIsoCountries.getName(countryCode, 'en', {
  select: 'official'
});
export const getStateAbbreviation = (state: string) => {
  if (!state) return state;
  const foundState = STATES.states.find(s => s.name.toLowerCase() === state.toLowerCase());
  return foundState ? foundState.abbreviation : state;
};

/**
 * @example
 * `Quotebeam Support; 5264 Romford Dr, San Jose, CA 95124`
 */
export const formatAddressLong = (address: Address): string => {
  if (!address || !address.street) return '';
  const {
    companyName = '',
    personName = '',
    countryCode,
    street,
    street2,
    city,
    state,
    zipCode
  } = address;
  const formattedCompanyName = companyName && companyName + '; ';
  const formattedPersonName = personName && personName + '; ';
  const formattedStreet2 = street2 && ' ' + street2;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = getCountryName(countryCode);
    return `${formattedCompanyName}${formattedPersonName}${countryName}, ${street}${formattedStreet2}, ${city}, ${state} ${zipCode}`;
  } else {
    const abbreviatedState = getStateAbbreviation(state);
    return `${formattedCompanyName}${formattedPersonName}${street}${formattedStreet2}, ${city}, ${abbreviatedState} ${zipCode}`;
  }
};

/**
 * @example
 * `San Jose, CA`
 */
export const formatAddressShort = (address: Address): string => {
  if (!address) return '';
  const {
    countryCode,
    city,
    state
  } = address;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = getCountryName(countryCode);
    return `${city}, ${countryName}`;
  } else {
    const abbreviatedState = getStateAbbreviation(state);
    return `${city}, ${abbreviatedState}`;
  }
};

/**
 * @example
 * `5264 Romford Dr
 * San Jose CA, 95124`
 */
export const formatAddressMultiLine = (address: Partial<Address>) => {
  if (!address) return null;
  const {
    countryCode,
    street,
    street2,
    city,
    state,
    zipCode
  } = address;
  const street2Formatted = street2 && ' ' + street2;
  if (countryCode !== 'USA' && countryCode !== 'US') {
    const countryName = countryCode ? getCountryName(countryCode) : '';
    return <>
        <span>
          {street}
          {street2Formatted}
        </span>
        <br />
        <span>{`${city} ${state}, ${zipCode} ${countryName}`}</span>
      </>;
  } else {
    const abbreviatedState = state ? ` ${getStateAbbreviation(state)}` : '';
    return <>
        <span>
          {street}
          {street2Formatted}
        </span>
        <br />
        <span>{`${city}${abbreviatedState}, ${zipCode}`}</span>
      </>;
  }
};